export const API_URL = process.env.REACT_APP_API_URL + 'api'
export const API_VERSION = 'v1'
export const DOC_URL = process.env.REACT_APP_DOC_URL

export const URL = API_URL + '/' + API_VERSION + '/'

export const TOKEN = URL + 'test'

export const LOGIN = URL + 'login'

export const VALIDATEACCOUNTUSER = URL + 'validate-account-user'

export const VALIDATEACCOUNTEXTERNALCOMPANY = URL + 'validate-account-external-company'

export const GETPLATFORMS = URL + 'platforms'

export const GETLANGUAGES = URL + 'languages'

export const NATIONALITIES = URL + 'nationalities'

export const REGISTER = URL + 'register'

export const CREATEACCOUNT = URL + 'create-account'

export const GETACCOUNTTYPES = URL + 'account-types'

export const GETACCOUNTLIST = URL + 'account-list'

export const CONTACTSLIST = URL + 'company/'

export const CONTACTSTYPE = URL + 'contact-types/'

export const DWPCORRESPONDENTS = URL + 'company/dwp-correspondents/'

export const DELETEDWPCORRESPONDENTS = URL + 'company/dwp-correspondents/delete-correspondent/'

export const OPERATIONALINFOS = URL + 'company/operational-infos/'

export const ENVIRONMENTALASPECTS = URL + 'company/environmental-aspects/'

export const DELETEENVIRONMENTALASPECTS =
	URL + 'company/environmental-aspects/{@1}/delete-file?filepath={@2}'

export const DELETEOPERATIONALMODE = URL + 'company/{@1}/operational-modes/{@2}'

export const ATTACHMENT = URL + 'company/attachment/'

export const ACCOUNTINFOS = URL + 'account/general-infos/'

export const OPENING_REQUESTS = URL + 'opening-requests/'

export const DOWNLOAD_FILE = URL + 'download-file'

export const DOCUMENT_DELETE_FILE = URL + 'platform/{@1}/documents/{@2}/doc?filepath={@3}'

export const CHANGE_STATE_OPENING_REQUESTS = URL + 'change-state-opening-request/'

export const DEACTIVATE_ACCOUNT = URL + 'platform/{@}/account/toggle-state/'

export const JOBS = URL + 'jobs/'

export const COMPANYMANAGER = URL + 'company-managers/'

export const CHEMICAL_PRODUCTS = URL + 'company/{@}/chemicals-products/'

export const DANGER_PHRASES = URL + 'danger-phrases'

export const PDPBYPLATFORM = URL + 'pdp-name-list'

export const NOTIFICATIONCOMPANIES = URL + 'notification-companies'

export const MASSNOTIFICATION = URL + 'platform/{@1}/mass-notification'

export const MASSNOTIFICATIONLIST = URL + 'platform/{@1}/mass-notification/diffusion-list'

export const OTHERDOCUMENTGET = URL + 'company/{@1}/other-document'

export const OTHERDOCUMENT = URL + 'company/operational-infos/{@1}/other-document/{@2}'

/* Prevention Plan*/

export const PDPINFORMATIONS = URL + 'platform/'

export const SECTORS = URL + 'sectors/'

export const UNITS = URL + 'units/'

export const ENTITIES = URL + 'activity-phases/'

export const PDPCONTACTS = URL + 'pdp-contacts/'

export const PDPWORKFLOW = URL + 'platform-workflow/'

export const PDPSUGGESTION = URL + 'pdp-number-suggestion/'

export const ACCOUNTBYTYPEPLATFORM = URL + 'account-by-typeplatform/'

export const GETCOMPANIESPDP = URL + 'platform/{@1}/companies/{@2}'

export const DELETECOMPANIESPDP = URL + 'platform/{@1}/companies/{@2}/delete-company/'

export const UPDATECOMPANIESPDP = URL + 'platform/{@1}/companies/{@2}/update-company/'

export const MASSIVEACTION = URL + 'platform/{@1}/companies/{@2}/massive-action'

export const PDPINFORMATIONSDRAFT = URL + 'draft/platform/'

export const SENDTODWP = URL + 'platform/{@1}/companies/{@2}/revision/{@3}/send-to-dwp'

export const GETSUBCONTRACTORS = GETCOMPANIESPDP + '/{@3}/sub-list'

/* Company record */

export const DEENVIRONMENTALASPECTS =
	URL + 'EnvironmentalAspects/platform/{@1}/company-record/{@2}/revision/{@3}'

export const COMPANYRECORDSUBMIT = URL + 'platform/{@1}/company-record/{@2}/submit'

export const COMPANYRECORDCONFIRMSUBMIT = URL + 'platform/{@1}/company-record/{@2}/submit/confirm'

export const HYGIENE = URL + 'platform/{@1}/company-record/{@2}/hygiene'

export const HYGIENECONTACT = URL + 'platform/{@1}/company-record/{@2}/hygiene-contact'

export const DELETE_HC = URL + 'platform/{@1}/company-record/{@2}/hygiene-contact/{@3}'

export const HYGIENESURVEILLANCEPOINT =
	URL + 'platform/{@1}/company-record/{@2}/hygiene-surveillance-point'

export const OPERATIONALMODE = URL + 'platform/{@1}/company-record/{@2}/operational-mode'

export const OPERATIONDESCRIPTION = URL + 'platform/{@1}/company-record/{@2}/operation-description'

export const INIT_OPERATION_DESCRIPTION =
	URL + 'platform/{@1}/company/{@2}/pdp/{@3}/revision/{@4}/operation-description'

export const POSTOPERATIONDESCRIPTION =
	URL + 'platform/{@1}/company/{@2}/company-record/operation-description'

export const GETCERTIFICATIONS = URL + 'platform/{@1}/company/{@2}/certifications'

export const ENVIRONMENTALASPECTSDE =
	URL + 'platform/{@1}/company-record/{@2}/environmental-aspects'

export const ACTIVITYPHASES = URL + 'platform/{@1}/company-record/{@2}/activity-phases'

export const SPECIFICACTIVITYPHASES =
	URL + 'platform/{@1}/company-record/{@2}/activity-phases/specific-activity'

export const ACTIVITYPHASESOBSERVATION =
	URL + 'platform/{@1}/company-record/{@2}/activity-phases/{@3}/observation'

export const CHEMICALPRODUCTS = URL + 'platform/{@1}/company-record/{@2}/chemical-products'

export const SUBCONTRACTORCOMPANIES =
	URL + 'platform/{@1}/company-record/{@2}/subcontracting-companies'

export const DELETE_SUBCONTRACTORCOMPANIES =
	URL + 'platform/{@1}/company-record/{@2}/subcontracting-company/{@3}'

export const NEWSUBCONTRACTORCOMPANIES =
	URL + 'platform/{@1}/company-record/{@2}/new-subcontractors'

export const COMPANYRECORDLIST = URL + 'platform/{@1}/company-record-list'

export const CHEVRONSSTATE = URL + 'platform/{@1}/company-record/{@2}/chevron-state'

export const NEWCOMPANYRECORDLIST = URL + 'platform/{@1}/company/{@2}/new-company-record-list'

export const COMPANYRECORD = URL + 'platform/{@1}/company-record/{@2}'

export const CSTATEVALIDATION = URL + 'platform/{@1}/company-record/{@2}/validation-state'

export const SENDAPPROBATION = URL + 'platform/{@1}/approbation/{@2}'

export const DEHISTORY = URL + 'platform/{@1}/company-record-history'

export const CREATE_NEW_REVISION_CR = URL + 'platform/{@1}/company-record/{@2}/new-revision'

/* Chevron validations url */

export const VALIDATION_URL = (type) => URL + `platform/{@1}/company-record/{@2}/${type}/validate`

export const VALIDATION_OPM = VALIDATION_URL('operational-mode')

export const VALIDATION_SBC = VALIDATION_URL('subcontracting-companies')

export const VALIDATION_HAH = VALIDATION_URL('hygiene')

export const VALIDATION_ACP = VALIDATION_URL('activity-phases')

export const VALIDATION_CHP = VALIDATION_URL('chemical-products')

export const VALIDATION_EVA = VALIDATION_URL('environmental-aspects')

export const VALIDATION_OPD = VALIDATION_URL('operation-description')

export const PRINCIPALCOMPANIESLIST = URL + 'platform/{@1}/company/{@2}/pdp/{@3}'

export const APPROBATION_CRL = URL + 'platform/{@1}/approbation/company-record-list'

export const MEASURE_TYPES = URL + 'measure-types/'

export const COUNT_TO_SENT_DWP =
	URL + 'platform/{@1}/companies/{@2}/revision/{@3}/count-sent-to-dwp'

export const RESTRICTEDPDPDURATION = URL + 'restricted-pdp-duration'

export const CHEVRONSSTATES = PDPINFORMATIONS + '{@1}/chevrons-state/{@2}/revision/{@3}'

export const PDPVALIDATION = PDPINFORMATIONS + '{@1}/validate/{@2}/revision/{@3}'

export const GENERALINFOS = PDPINFORMATIONS + '{@1}/general-infos/{@2}/revision/{@3}'

export const SETGENERALINFOS = PDPINFORMATIONS + '{@1}/general-infos'

export const AREAOPERATION = PDPINFORMATIONS + '{@1}/operation-areas/{@2}/revision/{@3}'

export const PDPSTATS = PDPINFORMATIONS + '{@1}/companies/{@2}/stats'

export const PDPDRAFTLIST = URL + 'draft/platform/{@1}/companies/{@2}'

export const PDP_CONTACTS = PDPINFORMATIONS + '{@1}/contacts/{@2}/revision/{@3}'

export const DELETE_PDP_CONTACTS = PDPINFORMATIONS + '{@1}/contacts/{@2}/delete-contact/{@3}'

export const PDP_DOCUMENTS = PDPINFORMATIONS + '{@1}/documents/{@2}/revision/{@3}'

export const PDP_DOCUMENTS_DOWNLOAD = PDP_DOCUMENTS + '/download'

export const SEND_PDP_CONVOCATION =
	PDPINFORMATIONS + '{@1}/communication/{@2}/revision/{@3}/send-convocation'

export const SEND_PDP_NOTIFICATION =
	PDPINFORMATIONS + '{@1}/communication/{@2}/revision/{@3}/send-notification'

export const COMMUNICATION_HISTORY = PDPINFORMATIONS + '{@1}/communication/{@2}/history'

export const PDP_NOTIFIABLE = PDPINFORMATIONS + '{@1}/diffusion/{@2}/revision/{@3}/is-diffuseable'

export const PDP_DIFFUSION_STATS = PDPINFORMATIONS + '{@1}/diffusion/{@2}/stats'

export const DIFFUSION_HISTORY = PDPINFORMATIONS + '{@1}/diffusion/{@2}/history'

export const PDP_DIFFUSION = PDPINFORMATIONS + '{@1}/diffusion/{@2}/revision/{@3}/send-diffusion'

export const RESTRICT_PDP_CREATE_COMP =
	PDPINFORMATIONS + '{@1}/restricted-pdp/{@2}/{@3}/create-company'

export const OTHER_DOCUMENT_DESCRIPTION = PDPINFORMATIONS + '{@1}/company/{@2}/other-document'

export const REPORTS_SUBCONTRACTORS = PDPINFORMATIONS + '{@1}/reporting/subcontractors'

export const REPORTS_ACTIVITY_PHASES = PDPINFORMATIONS + '{@1}/reporting/activity-phases'

export const REPORTS_SPECIFIC_ACTIVITY_PHASE =
	PDPINFORMATIONS + '{@1}/reporting/specific-activity-phases'

export const REPORTS_JOBS = PDPINFORMATIONS + '{@1}/reporting/jobs'

export const REPORTS_OBSERVATIONS = PDPINFORMATIONS + '{@1}/reporting/observations'

export const REPORTS_CHEMICALS = PDPINFORMATIONS + '{@1}/reporting/chemicals'

export const REPORTS_CHEMICALS_FDS = PDPINFORMATIONS + '{@1}/reporting/chemicals/fds-export'

export const REPORTS_COMPANY_LIST = PDPINFORMATIONS + '{@1}/reporting/company-list'

export const REPORTS_COMPANY_CONTACT_LIST = PDPINFORMATIONS + '{@1}/reporting/company-contact-list'

export const WORKFLOW = PDPINFORMATIONS + '{@1}/pdp-type/{@2}/workflow'

export const ALTERNATES_LIST = PDPINFORMATIONS + '{@1}/substitute-list'

export const ALTERNATES_ADD = PDPINFORMATIONS + '{@1}/substitute'

export const ALTERNATES_DELETE = PDPINFORMATIONS + '{@1}/substitute/{@2}/{@3}'

export const ALTERNATES_ELIGIBLE = PDPINFORMATIONS + '{@1}/eligible-substitute/{@2}'

export const ACTIVITYPHASESMANAGER = URL + 'api/ActivityPhaseManager/platform/{@1}/activity-phases'

export const PDPTYPES = URL + 'pdp-types'

export const SUPERVISION = PDPINFORMATIONS + '{@1}/supervision'

/* Reference documents */

export const DOC_TYPE = URL + 'document-types'

const REF_DOC = PDPINFORMATIONS + '{@1}/reference-documents/{@2}/'

export const GET_REF_DOCS_SIGNETS = REF_DOC + 'signets'

export const GET_REF_DOCS_INFOS = REF_DOC

export const POST_REF_DOC_PREVIEW = REF_DOC + 'preview'

export const POST_REF_DOC_PREVIEWINFO = REF_DOC + 'previewinfo'

export const POST_REF_DOC_PUBLISH = REF_DOC + 'publish/{@2}'

export const CR_VIEWS_BY_ROLE = URL + 'cr-views-by-role'

/* End Reference documents */

export const SHAREPOINT_URL = URL + 'external-sharepoint-links'

export const MAIL_ARCHIVE = '{@1}/mail-archive'

export const CHEMICAL_NOTIFICATION = URL + 'platform/{@1}/chemical-notifications'

export const CHECKLIST_COMPANY_RECORD = URL + 'platform/{@1}/check-list'

export const CHECKLISTITEM_COMPANY_RECORD = URL + 'platform/{@1}/check-list/{@2}'

export const DOCUMENTATION_AUTHENTICATION = DOC_URL + 'admin/{@1}/authentication'

export const PLATFORM_CONFIGURATION = URL + 'platform/{@1}/configuration'

export const PLATFORM_CONFIGURATION_SPECIFIC = PLATFORM_CONFIGURATION + '/{@2}'

export const RESEND_MAIL = 'resend-mail'
/* SPECIALS TREATMENTS */
export const GET_SIRET_CHANGEMENT = URL + 'platform/{@1}/siret-changement/{@2}'

export const POST_SIRET_CHANGEMENT = URL + 'platform/{@1}/siret-changement'

export const REFUSAL_MESSAGE = URL + 'platform/{@1}/company-record/refusal-messages'

export const REFUSAL_MESSAGE_DELETE = REFUSAL_MESSAGE + '/{@2}'
