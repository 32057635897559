import HeadTitle from '../../components/HeadTitle/HeadTitle'
import Form from '../../components/Form/Form'
import InputText from '../../components/Form/inputFullPage/InputText'
import api from '../../utils/Requester'
import { required, sectorUnitMinLength } from '../../utils/VerifyFields'
import { SECTORS, UNITS } from '../../constants/url'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { makeStyles } from '@material-ui/styles'
import {
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	Fab,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Typography,
	Switch,
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { composeValidators } from '../../utils/Form'

const useStyles = makeStyles((_theme) => ({
	content: {
		width: '95%',
		margin: 'auto',
	},
	listSector: {
		'& > span': {
			fontWeight: 'bold',
		},
	},
	actif: {
		'& > span': {
			color: '#134391',
		},
	},
	inactif: {
		'& > span': {
			color: 'rgba(0,0,0,0.2)',
		},
	},
	popupTitle: {
		padding: '10px 20px',
	},
	button: {
		backgroundColor: _theme.palette.primary.main,
		borderColor: _theme.palette.secondary.main,
		color: _theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		padding: 7,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
}))

function SectorsUnitsManagement() {
	const SECTOR = 'sector'
	const UNIT = 'unit'
	const classes = useStyles()
	const { t } = useTranslation('user')
	const [listSectors, setListSectors] = useState([])
	const [cookies] = useCookies()
	const [editItem, setEditItem] = useState({
		id: null,
		name: null,
		isActif: false,
		isOpera: false,
		platformId: null,
		sectorId: null,
	})
	const [editItemType, setEditItemType] = useState('')
	const [openEditPopUp, setOpenEditPopUp] = useState(false)
	const [sectorActive, setSectorActive] = useState(false)
	const [accordionState, setAccordionState] = useState({
		all: false,
		numberOpen: 0,
		totalOpened: 0,
	})
	const { enqueueSnackbar } = useSnackbar()

	const recoverySectors = (data) => {
		if (data) {
			setListSectors(
				data.map((sector) => ({
					...sector,
					open: listSectors.find((s) => s.id == sector.id)?.open ?? false,
				})),
			)
			setAccordionState({ ...accordionState, totalOpened: data.length })
		}
	}

	useEffect(() => {
		async function fetchData() {
			await api
				.get(SECTORS, {
					p_PlatformId: parseInt(cookies[PLATFORM_ID_COOKIE]),
					p_IsActif: sectorActive,
				})
				.then((res) => recoverySectors(res.data))
		}
		fetchData()
	}, [sectorActive])

	const handleClickOpenCollapse = (sectorId, state = null) => {
		let foundIndex = listSectors.findIndex((x) => x.id == sectorId)
		let valueCollapse = listSectors[foundIndex].open
		listSectors[foundIndex].open = state === null ? !valueCollapse : state
		if (listSectors[foundIndex].open) {
			setAccordionState({
				...accordionState,
				numberOpen: accordionState.numberOpen + 1,
				all: accordionState.numberOpen + 1 === accordionState.totalOpened,
			})
		} else {
			setAccordionState({
				...accordionState,
				numberOpen: accordionState.numberOpen - 1,
				all: false,
			})
		}
		setListSectors((l) => [...l])
	}

	const handleAllTriggerCollapse = () => {
		listSectors.forEach((x) => handleClickOpenCollapse(x.id, !accordionState.all))
		if (!accordionState.all) {
			setAccordionState({ ...accordionState, numberOpen: accordionState.totalOpened, all: true })
		} else {
			setAccordionState({ ...accordionState, numberOpen: 0, all: false })
		}
	}

	const onClickAddEdit = (element, platformId, sectorId, id, name, isActif, isOpera) => {
		setOpenEditPopUp(true)

		if (id !== null)
			setEditItem({
				id: id,
				name: name != null ? name : editItem.name,
				isActif: isActif != null ? isActif : editItem.isActif,
				isOpera: isOpera != null ? isOpera : editItem.isOpera,
				platformId: platformId != null ? platformId : editItem.platformId,
				sectorId: sectorId != null ? sectorId : editItem.sectorId,
			})
		else
			setEditItem({
				id: null,
				name: null,
				isActif: true,
				isOpera: false,
				platformId: platformId != null ? platformId : editItem.platformId,
				sectorId: sectorId != null ? sectorId : editItem.sectorId,
			})

		setEditItemType(element)
	}

	const upsert = (values) => {
		const isActif = values.isActif !== undefined ? values.isActif : false
		const isOpera = values.isOpera !== undefined ? values.isOpera : false
		const formData = {
			id: editItem.id,
			name: values.name === undefined ? editItem.name : values.name,
			isActif: values.isActif === undefined ? editItem.isActif : isActif,
			isOpera: values.isOpera === undefined ? editItem.isOpera : isOpera,
			platformId: editItem.platformId,
			sectorId: editItem.sectorId,
		}
		const unitUrl = editItemType === UNIT ? UNITS : undefined
		const apiUrl = editItemType === SECTOR ? SECTORS : unitUrl

		if (apiUrl !== undefined)
			api.put(apiUrl, formData).then((res) => {
				const { data, ok } = res
				setOpenEditPopUp(false)
				if (ok) {
					hasResOk(data)
				} else {
					hasResNok(data)
				}
			})
	}

	const hasResOk = (data) => {
		if (data.value) recoverySectors(data.value)
		else recoverySectors(data)

		enqueueSnackbar(editItemType == SECTOR ? t('sectorSaved') : t('uniteSaved'), {
			variant: 'success',
		})
	}

	const hasResNok = (data) => {
		if (data.data?.undisabledUnits?.length > 0) {
			enqueueSnackbar(
				t('sectorSavedWarning', { units: data.data.undisabledUnits.map((u) => u.name).join(', ') }),
				{ variant: 'error' },
			)
		} else if (data)
			data.forEach((d) => {
				enqueueSnackbar(t(d.code), { variant: 'error' })
			})
		else
			enqueueSnackbar(
				editItemType == SECTOR ? t('update_sector_failed') : t('update_unit_failed'),
				{ variant: 'error' },
			)
	}

	const handleClose = () => {
		setOpenEditPopUp(false)
	}

	const PopUp = (props) => {
		const { onClose, open, onSubmit: onSubmitEvent, defaultData } = props
		const name = editItemType === SECTOR ? t('newSector') : t('newUnite')
		const initValues = { name: defaultData.name }
		return (
			<Dialog open={open} onClose={onClose} fullWidth={true}>
				<Typography variant="h6" className={classes.popupTitle}>
					{editItemType === SECTOR ? t(SECTOR) : t('unite')}
				</Typography>
				<Form onSubmit={onSubmitEvent} initialValues={initValues}>
					<DialogContent>
						<InputText
							name="name"
							label={name}
							validate={composeValidators(required, sectorUnitMinLength)}
						/>
						<Field
							name="isActif"
							type="checkbox"
							render={({ input }) => (
								<FormControl {...input}>
									<FormControlLabel
										control={
											<Switch size="normal" name="isActif" defaultChecked={defaultData.isActif} />
										}
										name="isActifLabel"
										label={editItemType === SECTOR ? t('sectorIsActif') : t('uniteIsActif')}
										labelPlacement="start"
									/>
								</FormControl>
							)}
						/>
						{editItemType === UNIT && (
							<Field
								name="isOpera"
								type="checkbox"
								render={({ input }) => (
									<FormControl {...input}>
										<FormControlLabel
											control={
												<Switch size="normal" name="isOpera" defaultChecked={defaultData.isOpera} />
											}
											name="isOperaLabel"
											label={editItemType === SECTOR ? t('sectorIsOpera') : t('uniteIsOpera')}
											labelPlacement="start"
										/>
									</FormControl>
								)}
							/>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="secondary">
							{t('cancel')}
						</Button>
						<Button type="submit" color="secondary" autoFocus>
							{t('validate')}
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		)
	}
	PopUp.propTypes = {
		onClose: PropTypes.func,
		open: PropTypes.bool,
		onSubmit: PropTypes.func,
		defaultData: PropTypes.object,
	}

	return (
		<>
			<PopUp
				open={openEditPopUp}
				onSubmit={upsert}
				defaultData={editItem}
				onClose={() => {
					setOpenEditPopUp(false)
				}}
			/>
			<div className={classes.content}>
				<HeadTitle title={t('sectorsUnitsManagement')} />
				<Field
					name="sectorActive"
					type="checkbox"
					render={({ input }) => (
						<FormControl {...input}>
							<FormControlLabel
								control={
									<Switch
										size="normal"
										name="sectorActive"
										defaultChecked={sectorActive}
										onClick={() => setSectorActive(!sectorActive)}
									/>
								}
								name="sectorActive"
								label={t('sectorActive')}
								labelPlacement="start"
							/>
						</FormControl>
					)}
				/>
				<div>
					<Grid container direction="row" justify="flex-start" alignItems="center">
						<Field
							name="toggleAll"
							type="checkbox"
							render={({ input }) => (
								<FormControl {...input}>
									<FormControlLabel
										control={
											<Switch
												size="normal"
												name="toggleAll"
												defaultChecked={accordionState.all}
												checked={accordionState.all}
												onClick={handleAllTriggerCollapse}
											/>
										}
										name="toggleAll"
										label={t('sectorManagement_toggle_opened')}
										labelPlacement="start"
									/>
								</FormControl>
							)}
						/>
					</Grid>
				</div>
				<div>
					<List component="nav">
						{listSectors.map((sector) => {
							return (
								<div key={sector.id}>
									<ListItem
										name={`sector-${sector.id}`}
										button
										key={sector.id}
										value={sector.id}
										onClick={() => handleClickOpenCollapse(sector.id)}
									>
										<ListItemText
											primary={sector.name}
											className={`${classes.listSector} ${
												sector.isActif ? classes.actif : classes.inactif
											}`}
										/>
										<ListItemSecondaryAction style={{ marginRight: 40 }}>
											<IconButton
												edge="start"
												onClick={(event) => {
													event.stopPropagation()
													onClickAddEdit(
														SECTOR,
														cookies[PLATFORM_ID_COOKIE],
														null,
														sector.id,
														sector.name,
														sector.isActif,
														null,
													)
												}}
											>
												<EditIcon />
											</IconButton>
										</ListItemSecondaryAction>
										{sector.open ? <ExpandLess /> : <ExpandMore />}
									</ListItem>
									<Collapse in={sector.open} timeout="auto">
										<List component="div" disablePadding>
											{sector.units &&
												sector.units.map((unit) => (
													<ListItem key={unit.id}>
														<ListItemText
															inset
															primary={unit.name}
															className={`${
																unit.isActif
																	? classNames(classes.actif)
																	: classNames(classes.inactif)
															}`}
														/>
														<ListItemSecondaryAction>
															<IconButton
																edge="start"
																onClick={() =>
																	onClickAddEdit(
																		UNIT,
																		cookies[PLATFORM_ID_COOKIE],
																		sector.id,
																		unit.id,
																		unit.name,
																		unit.isActif,
																		unit.isOpera,
																	)
																}
															>
																<EditIcon />
															</IconButton>
														</ListItemSecondaryAction>
													</ListItem>
												))}
										</List>
										<Grid container direction="row" justify="center" alignItems="center">
											<Grid container item xs={4} justify="center">
												<Fab
													color="secondary"
													variant="extended"
													onClick={() =>
														onClickAddEdit(
															UNIT,
															cookies[PLATFORM_ID_COOKIE],
															sector.id,
															null,
															null,
															null,
															null,
														)
													}
													style={{ marginBottom: 10 }}
												>
													{t('addUnite')}
												</Fab>
											</Grid>
										</Grid>
									</Collapse>
								</div>
							)
						})}
					</List>
				</div>
				<Grid container direction="row" justify="flex-end" alignItems="center">
					<Grid container item xs={4} justify="center">
						<Fab
							color="secondary"
							variant="extended"
							style={{ marginBottom: 50 }}
							onClick={() =>
								onClickAddEdit(SECTOR, cookies[PLATFORM_ID_COOKIE], null, null, null, null, null)
							}
						>
							{t('addSector')}
						</Fab>
					</Grid>
				</Grid>
			</div>
		</>
	)
}

export default SectorsUnitsManagement
